<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ getAppName() }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $i18n.t('Forgot Password?') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $i18n.t("messages.reset_password") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForm"
            >
              <b-form-group
                  :label="$i18n.t('Email')"
                  label-for="forgot-password-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                >
                  <b-form-input
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-email"
                      placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                  type="submit"
                  variant="primary"
                  block
              >
                {{ $i18n.t('Send reset link') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $i18n.t('back_to_login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {email, required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http.post('/auth/forgot-password', {
            email: this.userEmail,
          }).then(response => {
            this.$router.replace('login')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$i18n.t('Success'),
                      icon: 'EditIcon',
                      variant: 'success',
                      text: response.data.message
                    },
                  })
                })
          }).catch(error => {
            if (error.data && error.data.errors) {
              this.$refs.simpleRules.setErrors(error.data.errors)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
